<template>
  <div>
    <vs-row vs-justify="center">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-card>
          <div slot="header">
            <h3>
              {{$t('Create Users')}}
            </h3>
          </div>
          <div>
            <vs-row type="flex" vs-justify="space-around" vs-align="center">
              <vs-col vs-w="2" vs-sm="12">
                <vs-row align="center">
                  <vs-col vs-w="12">
                    <vs-avatar size="large"/>
                  </vs-col>
                  <vs-col vs-w="12">
                    <vs-button color="primary"
                               class="ml-auto mt-2">
                      {{$t('Change Image')}}
                    </vs-button>
                  </vs-col>
                </vs-row>
              </vs-col>
              <vs-col vs-w="8" vs-sm="12">
                <template>
                  <div class="vx-row">
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input class="w-full" :label-placeholder="$t('First Name')" v-model="UserData.name"/>
                      <span v-if="errors.name" class="text-danger text-sm valdation_error_avatar" v-show="errors.name">
                        {{errors.name[0]}}
                      </span>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input class="w-full" :label-placeholder="$t('Last Name')" v-model="UserData.last_name"/>
                      <span v-if="errors.last_name" class="text-danger text-sm valdation_error_avatar" v-show="errors.last_name">
                        {{errors.last_name[0]}}
                      </span>
                    </div>
                  </div>
                  <div class="vx-row">
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input class="w-full" :label-placeholder="$t('User Name')" v-model="UserData.user_name"/>
                      <span v-if="errors.user_name" class="text-danger text-sm valdation_error_avatar" v-show="errors.user_name">
                        {{errors.user_name[0]}}
                      </span>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input class="w-full" :label-placeholder="$t('TC')" v-model="UserData.tc"/>
                      <span v-if="errors.tc" class="text-danger text-sm valdation_error_avatar" v-show="errors.tc">
                        {{errors.tc[0]}}
                      </span>
                    </div>
                  </div>
                  <div class="vx-row">
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input type="email" class="w-full" :label-placeholder="$t('Email')" v-model="UserData.email"/>
                      <span v-if="errors.email" class="text-danger text-sm valdation_error_avatar" v-show="errors.email">
                        {{errors.email[0]}}
                      </span>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input type="phone" class="w-full" :label-placeholder="$t('Phone')" v-model="UserData.mobile"/>
                      <span v-if="errors.mobile" class="text-danger text-sm valdation_error_avatar" v-show="errors.mobile">
                        {{errors.mobile[0]}}
                      </span>

                    </div>
                  </div>
                  <div class="vx-row">
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                          <span v-if="UserData.languages" class="select-label-placeholder">
                        {{$t('Language')}}
                      </span>
                      <my-select                         :placeholder="$t('Select Language')"
                        class="selectExample mt-5"
                        style="width: 100%"
                        v-model="UserData.languages">
                        <vs-select-item :key="index" :value="item" :text="item" v-for="(item,index) in LanguageOption"/>
                      </my-select>
                      <span v-if="errors.languages" class="text-danger text-sm valdation_error_avatar" v-show="errors.languages">
                        {{errors.languages[0]}}
                      </span>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                          <span v-if="UserData.status" class="select-label-placeholder">
                        {{$t('Status')}}
                      </span>
                      <my-select                         :placeholder="$t('Select Status')"
                        class="selectExample mt-5"
                        style="width: 100%"
                        v-model="UserData.status">
                        <vs-select-item :key="index" :value="item" :text="$t(item)" v-for="(item,index) in StatusOption"/>
                      </my-select>
                      <span v-if="errors.status" class="text-danger text-sm valdation_error_avatar" v-show="errors.status">
                        {{errors.status[0]}}
                      </span>
                    </div>
                  </div>
                  <div class="vx-row">

                    <!-- <div class="vx-col sm:w-1/2 w-full mb-2">
                      <span v-if="UserData.Role.length>0" class="select-label-placeholder">
                        {{$t('Role')}}
                      </span>
                      <my-select                         :placeholder="$t('Select Role')"
                        class="selectExample mt-5"
                        style="width: 100%"
                        multiple
                        v-model="UserData.Role">
                        <vs-select-item :key="index" :value="item.id" :text="$t(item.name)"
                                        v-for="(item,index) in RoleOption"/>
                      </my-select>
                      <span v-if="errors.Role" class="text-danger text-sm valdation_error_avatar" v-show="errors.Role">
                        {{errors.Role[0]}}
                      </span>
                    </div> -->
                  </div>
                </template>
              </vs-col>
              <vs-col vs-w="12">
                <br/>
                <br/>
                <br/>
              </vs-col>
              <vs-col vs-w="11" align="end">
                <vs-button color="success" class="mr-3 mb-2" type="gradient" @click="CreateNewUser()">{{$t('Confirm')}}</vs-button>
                <vs-button color="warning" type="gradient" class="mb-2">{{$t('Reset')}}</vs-button>
              </vs-col>
            </vs-row>
          </div>

        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import MySelect from '../../../components/MySelect'

export default {
  /* eslint-disable */
  components:{MySelect},
  data:()=>({
    StatusOption: ['Active', 'Blocked', 'Deactivated'],
    errors:[],
    RoleOption: [
      {name: 'Role1', id: '1'},
      {name: 'Role2', id: '2'},
      {name: 'Role3', id: '3'},
    ],
    LanguageOption: [ 'English', 'Turkish'],
    UserData: {
      "name": "",
      "last_name": "",
      "user_name": "",
      "email": "",
      "mobile": "",
      "tc": "",
      "status": "",
      "languages": "",
      "Role": [],

    },
  }),

  methods:{
    CreateNewUser(){
      this.$store.dispatch('user/CreateUser',this.UserData).catch((error) => {
        if(error.response.data.errors)
          this.errors = error.response.data.errors
      })
    }
  },
  mounted(){

  },
  created() {

  }
}
</script>
<style>
.select-label-placeholder {
  position: absolute;
}
</style>
